import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from "gatsby-plugin-image"
import { MarkdownRemark } from "../types/markdown-page-props"
import { SiteMetadata } from "../types/site-metadata"

interface OverviewTemplateProps {
  data: {
    markdownRemark: MarkdownRemark
    site: {
      siteMetadata: SiteMetadata
    }
  }
  pageContext: {
    data: {
      category: string
      path: string
      title: string
      description: string
      sections: OverviewPageSection[]
      images: any
    }
    supportLink: string
    privacyPolicyLink: string
    apiDocumentationLink: string
    algoliaDocsPagesIndexName: string
  }
}

interface OverviewPageSection {
  type: string
  title?: string
  description?: string
  markdownText?: string
  markdownHtml?: string
  cards?: OverViewPageSectionCard[]
}

interface OverViewPageSectionCard {
  title?: string
  description?: string
  link?: string
  imagePath?: string
  imageData?: IGatsbyImageData
}

export default function OverviewTemplate(props: OverviewTemplateProps) {
  const { pageContext } = props
  const pageData = pageContext.data

  pageData.sections?.forEach(s => {
    s.cards?.forEach(c => {
      const filteredImages = pageData.images.filter(
        (x: any) => x.parent?.relativePath === c.imagePath
      )
      if (filteredImages.length > 0) {
        c.imageData = filteredImages[0].gatsbyImageData
      }
    })
  })

  return (
    <div className="container">
      <div
        className="column is-12-fullhd is-12-desktop is-12-tablet is-12-mobile"
        style={{ padding: "0 40px" }}
      >
        <div className="content">
          <main>
            <div style={{ padding: "15px" }}>
              <h1>{pageData.title}</h1>
              <p>{pageData.description}</p>
            </div>
            <div>
              {pageData.sections?.map(section => {
                return section.type === "cards" ? (
                  <div key={`section-wrapper-${section.title}`}>
                    {section.title && section.description && (
                      <div className="columns">
                        <div className="column is-12">
                          <h3>{section.title}</h3>
                          <p>{section.description}</p>
                        </div>
                      </div>
                    )}
                    <div className="columns" key={`section-${section.title}`}>
                      {section.cards &&
                        section.cards.map(card => {
                          const imageData = getImage(card.imageData as ImageDataLike)

                          return (
                            <div
                              className="column is-4-fullhd is-4-desktop is-4-tablet is-12-mobile is-flex is-align-items-stretch"
                              key={`card-${card.title}`}
                            >
                              <div className="card">
                                {card.link && (
                                  <Link to={card.link}>
                                    <div className="card-image">
                                      <figure className="image m-0">
                                        {imageData && (
                                          <GatsbyImage
                                            image={imageData}
                                            alt="A corgi smiling happily"
                                            width={200}
                                            height={200}
                                          />
                                        )}
                                      </figure>
                                    </div>
                                  </Link>
                                )}
                                <div className="card-content">
                                  <div className="title is-6">
                                    {card.link && <Link to={card.link}>{card.title}</Link>}
                                  </div>
                                  <p className="is-size-6">{card.description}</p>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                ) : (
                  <div key={`section-wrapper-${section.title}`}>
                    <div className="columns">
                      <div className="column is-12">
                        {section.markdownHtml && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: section.markdownHtml,
                            }}
                          ></div>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}
